import React from "react";
import "./style.scss";

function Footer() {
	return (
		<footer className="footer">
			<div className="footer-strip"></div>
			<div className="container">
				<div className="row p-4">
					<div className="col text-center">
						<p className="text-light">  © {new Date().getFullYear()} ДорСтройЯн. Все Права Защищены.</p>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
