import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
import { getContactInfo } from '../../services';

function Header() {
    const [contactInfo, setContactInfo] = useState({ phone: '', address: '' });

    useEffect(() => {
        const fetchData = async function () {
            const result = await getContactInfo();

            setContactInfo(result);
        }

        fetchData();
    }, []);

    return (
        <header className="ss-header p-1">
            <div className="container">
                <div className="row">
                    <div className="col"></div>
                    <div className="col-auto d-none d-md-flex">
                        <IconText icon={faEnvelope} type="mailto" contentItems={[contactInfo.email]} />
                    </div>
                    <div className="col col-auto">
                        <IconText icon={faMobileAlt} type="tel" contentItems={[contactInfo.phone, contactInfo.phoneAlt]} />
                    </div>
                </div>
            </div>
        </header>
    );
}

function IconText({ icon, contentItems, type }) {
    return (
        <div>
            <FontAwesomeIcon icon={icon} className="ss-thumb-icon" />
            {contentItems.map((ci, i) => <span key={i}>{i === 0 ? "" : ","} <a className="link-light ms-1" href={`${type}:${ci}`}>{ci}</a></span>)}
        </div>
    )
}

export default Header;