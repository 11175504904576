
import { Navbar, Nav, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function MainNavbar() {
    const navigate = useNavigate();

    var onNavigate = (path) => {
        navigate(`${path}/${new Date().getTime()}`);
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="/"><Logo /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link onClick={() => onNavigate('/home/services')}>НАШИ СЕРВИСЫ</Nav.Link>
                        <Nav.Link onClick={() => onNavigate('/home/whywe')}>ПОЧЕМУ МЫ</Nav.Link>
                        <Nav.Link onClick={() => onNavigate('/home/projects')}>ПРОЕКТЫ</Nav.Link>
                        <Nav.Link onClick={() => onNavigate('/home/about')}>О КОМПАНИИ</Nav.Link>
                        <Nav.Link onClick={() => onNavigate('/home/review')}>ОТЗЫВЫ</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

function Logo() {
    const baseUrl = process.env.PUBLIC_URL;

    return <img src={baseUrl + '/logo139_w.png'} className="ss-logo" alt="logo" />;
}

export default MainNavbar;