import React from "react";
import PageSection from "../../../common/pagesection";

import "./style.scss";

function AboutCompany({ aboutCompany }) {
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <div>
      <PageSection
        header={aboutCompany.header}
        description={aboutCompany.description}
        alternate="true">
        <div className="container ss-about-company">
          <div className="row">
            <div className="col-auto d-none d-md-block">
              <div className="ss-img-frame ps-2 pb-2">
                <img src={baseUrl + '/' + aboutCompany.imageUrl} alt="" />
              </div>
            </div>
            <div className="col">
              <div className="text-start">
                <h3>{aboutCompany.textHeader}</h3>
                <p>{aboutCompany.text}</p>
                <div className="mt-3">{aboutCompany.author}</div>
                <div className="float-end pt-3">
                  <img src={baseUrl + '/' + aboutCompany.signatureUrl} alt="signature" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSection>
    </div>
  );
}

export default AboutCompany;
