import React, { useEffect, useRef, useState } from 'react'
import Slider from '../../common/slider';
import { getHomeConfiguration } from '../../services';
import LatestProjects from './latestprojectssection';
import OurServicesSection from './ourservicessection';
import StatisticsSection from './statisticssection';
import WhyChooseUsSection from './whychooseussection';
import AboutCompany from './aboutcompanysection';
import ClientSay from './clientsaysection';
import ClientLogos from './clientslogosection';
import { useParams } from 'react-router-dom';


function Home() {

    let { section, ts } = useParams();

    const [scrollSections] = useState({
        services: useRef(),
        whywe: useRef(),
        projects: useRef(),
        about: useRef(),
        review: useRef()
    });

    const defaultConfiguration = {
        slides: [],
        ourServices: { header: "", description: "", serviceList: [] },
        whyChooseUs: { header: "", description: "", items: [] },
        statistics: {
            roadBuilt: { lengthKm: null, label: "" },
            successProject: { count: null, label: "" },
            happyClient: { count: null, label: "" },
            employees: { count: null, label: "" }
        },
        latestProjects: { header: "", description: "", images: [] },
        aboutCompany: { header: "", description: "", author: "" },
        clientSay: { header: "", description: "", clientSays: [] },
        clientLogo: { logos: [] }
    };

    const [homeConfiguration, setHomeConfiguration] = useState(defaultConfiguration);

    useEffect(() => {
        const fetchData = async function () {
            const result = await getHomeConfiguration();
            setHomeConfiguration(result);
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (!section || !scrollSections || !homeConfiguration)
            return;

        if (section in scrollSections) {
            scrollSections[section].current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [ts, section, homeConfiguration, scrollSections]);

    return (
        <>
            <Slider slides={homeConfiguration.slides} />
            <div ref={scrollSections.services}>
                <OurServicesSection ourServices={homeConfiguration.ourServices} />
            </div>
            <div ref={scrollSections.whywe}>
                <WhyChooseUsSection whyChooseUs={homeConfiguration.whyChooseUs} />
            </div>
            <StatisticsSection statistics={homeConfiguration.statistics} />
            <div ref={scrollSections.projects}>
                <LatestProjects latestProjects={homeConfiguration.latestProjects} />
            </div>
            <div ref={scrollSections.about}>
                <AboutCompany aboutCompany={homeConfiguration.aboutCompany} />
            </div>
            <div ref={scrollSections.review}>
                <ClientSay clientSay={homeConfiguration.clientSay} />
            </div>
            <ClientLogos clientLogos={homeConfiguration.clientLogo} />
        </>
    );
}

export default Home;