import React from 'react'
import PageSection from '../../../common/pagesection'
import PhotoGallery from '../../../common/photogalery'

import "./index.scss"

function LatestProjects({ latestProjects }) {
	return (
		<>
			<PageSection
				header={latestProjects.header}
				description={latestProjects.description} >
				<div className='container ss-latest-projects'>
					<PhotoGallery images={latestProjects.images} />
				</div>

			</PageSection>
		</>
	)
}

export default LatestProjects 