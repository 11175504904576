import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faClipboardList, faPeopleGroup, faPersonDigging } from '@fortawesome/free-solid-svg-icons'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import "./index.scss";

function StatisticsSection({ statistics }) {
    var roadBuilt = statistics.roadBuilt;
    var projCount = statistics.successProject;
    var clientCount = statistics.happyClient;
    var employees = statistics.employees;

    return (
        <div className="ss-statistics pt-4 pb-4">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6 col-lg-3">
                        <StatisticsItem value={roadBuilt.lengthKm} label={roadBuilt.label} suffix="км+" icon={faRoad} />
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-3">
                        <StatisticsItem value={projCount.count} label={projCount.label} suffix="+" icon={faClipboardList} />
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-3">
                        <StatisticsItem value={clientCount.count} label={clientCount.label} suffix="+" icon={faPeopleGroup} />
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-3">
                        <StatisticsItem value={employees.count} label={employees.label} suffix="" icon={faPersonDigging} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function StatisticsItem({ value, label, suffix, icon }) {
    return (
        <div className="ss-statistics-item text-nowrap mb-1">
            <div className="row">
                <div className="col">
                    <div className="fw-bolder fs-3 d-inline-block">
                        <FontAwesomeIcon icon={icon} className="d-inline-block me-2" />
                        <StatisticsCountUp value={value} suffix={suffix} />
                    </div>
                    <div className="fw-bold fs-6 text-nowrap">{label}</div>
                </div>
            </div>
        </div>
    );
}

function StatisticsCountUp({ value, suffix }) {
    const { ref, inView } = useInView();
    return (
        <div ref={ref} className="d-inline-block">
            {inView ? <CountUp end={value} suffix={suffix ?? ''} duration={1} /> : null}
        </div>
    );
}

export default StatisticsSection;