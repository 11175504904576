import React from "react";

import "./style.scss";

function ClientLogos({ clientLogos }) {
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <div className="ss-client-logos-section pt-2 pb-3">
      <div className="container">
        <div className="row">
          {clientLogos.logos.map(
            (item, index) => <div className="col-xs-12 col-md-6 col-lg-2 pt-2 pb-2" key={index}>
              <a href={item.url} target="_blank">
                <img alt="" src={baseUrl + '/' + item.imageUrl} />
              </a>
            </div>)}
        </div>
      </div>
    </div>
  );
}


export default ClientLogos;
