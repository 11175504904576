import { useEffect, useState } from "react";
import { getContactInfo } from "../../../services";

function ContactUs() {
    var [contactInfo, setContactInfo] = useState({ phone: '', phoneAlt: '', address: '', email: '' });

    useEffect(() => {
        async function loadContactInfo() {
            var ci = await getContactInfo();
            setContactInfo(ci);
        }

        loadContactInfo();
    }, []);

    return <>
        <div>
            Свяжитесь с нами по телефону {<PhoneNumber number={contactInfo.phone} />}, {<PhoneNumber number={contactInfo.phoneAlt} />} или по почте {<Email email={contactInfo.email} />}</div>
    </>
}

function PhoneNumber({ number }) {
    if (!number)
        return;

    return <a href={`tel:${number}`} className="text-nowrap">{number}</a>;
}

function Email({ email }) {
    if (!email)
        return;

    return <a href={`mailto:${email}`} className="text-nowrap">{email}</a>;
}

export default ContactUs;