import './index.scss'

function PageSection(props) {
    return (
        <div className={"ss-page-section " + (props.alternate ? "bg-alternate" : "bg-decoration")}>
            <div>
                <h3>{props.header}</h3>
                <PageSectionSeperator />
                {props.description && <p className="text-muted">{props.description}</p>}
                <div>{props.children}</div>
            </div>
        </div>
    );
}

function PageSectionSeperator() {
    return (
        <div className="ss-separator">
            <div className="ss-separator-line"></div>
            <div className="ss-separator-box"></div>
            <div className="ss-separator-line"></div>
        </div>
    );
}

export default PageSection;